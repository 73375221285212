export const comments: Comment[] = [
    {
        source: "buymeacoffee",
        date: new Date("2024-12-18"),
        username: "Matthew",
        text: "Thank you from Australia. Found via Reddit and helped with our Xmas organisation. So many other annoying email tools out there, good work and simple ethos. My friends were very relieved that such a solution existed.",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-12-04"),
        username: "Rob",
        text: "This is awesome. I was just thinking to build a free tier Vercel app for this purpose and came across your post on Reddit. Cheers and wishing you happy holidays!",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-11-26"),
        username: "rhymeswithjello",
        text: "Loved the Let's Be Santa app. It saved me a lot of headaches for this year's office party! Thank you.",
    },
    {
        source: "reddit",
        date: new Date("2024-12-07"),
        username: "moonrise-kingdom-09",
        text: "Thanks OP! Love this! May you and your team receive the BEST gifts each Christmas!",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-12-18"),
        username: "Alex",
        text: "Really nice app appreciate it is not collecting emails.",
    },
    {
        source: "reddit",
        date: new Date("2024-11-10"),
        username: "Nizten",
        text: "A great solution, I was considering using a discord bot to draw names for a group of friends in a server but this is even easier to set up. Thank you for making this!",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-12-17"),
        username: "Someone",
        text: "Cool tool. Thanks for solving this problem with an elegant little app!",
    },
    {
        source: "reddit",
        date: new Date("2024-12-05"),
        username: "StudentDrowning",
        text: "Awesome work!! so glad I found this.",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-12-11"),
        username: "Someone",
        text: "So much nicer than the websites that require emails - thank you!",
    },
    {
        source: "reddit",
        date: new Date("2024-12-18"),
        username: "nk___1",
        text: "I just used this and it worked flawlessly. You're incredible! Thank you!",
    },
    {
        source: "buymeacoffee",
        date: new Date("2024-12-06"),
        username: "Someone",
        text: "Thank you for simple ad-free app that doesn't extort your contact information.",
    },
    {
        source: "reddit",
        date: new Date("2023-12-13"),
        username: "yabba-dabba29",
        text: "Solid work!! The simplicity was great for a family of all ages.",
    },
];

export type Comment = {
    source: "reddit" | "buymeacoffee"
    username: String
    text: String
    date: Date
}