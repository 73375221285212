import { Box, Center, HStack, Text, VStack } from "@chakra-ui/layout";
import { Alert, AlertDescription, AlertIcon, AlertTitle } from "@chakra-ui/react";
import { SubmitButton } from "../components/SubmitButton";
import { BuyMeCoffee } from "../components/BuyMeCoffee";
import { Trans, useTranslation } from "react-i18next";

interface ComponentProps {
  eventName: string;
  eventId: string;
  giverName: string;
  giftedName: string;
  onBack: () => void;
}

export const CheckSecretSantaComponent = (props: ComponentProps) => {
  const { giverName, giftedName, onBack } = props;
  const { t } = useTranslation();
  return (
    <Box>
      <Alert
        status="success"
        variant="subtle"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          <Trans i18nKey="checked.text" values={{ giverName }}/>
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text my="3" fontSize="32">{giftedName}</Text>
        </AlertDescription>
      </Alert>
      <VStack>      
        <Text fontSize="24" m="" >
          <Trans i18nKey="checked.buymeacoffe"/>
        </Text>
        <BuyMeCoffee/>
      </VStack>
      <Center>
        <SubmitButton name={t('checked.go_back')} onClick={onBack} />
      </Center>
    </Box>
  );
}