import { Center, Text } from "@chakra-ui/layout";
import { Badge, Spacer } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  checked: boolean;
  name: string
}

export const ParticipantRow = (props: ComponentProps) => {
  const { checked, name } = props;
  const { t } = useTranslation();
  return (
    <Center>
      <Text>{name}</Text>
      <Spacer />
      <Badge variant="subtle" colorScheme={checked ? "green" : "red"}>
        {checked ? t('status.checked') : t('status.waiting')}
      </Badge>
    </Center>
  );
}
