import { US, PL, IT, FR } from 'country-flag-icons/react/3x2'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import { Button, Menu, MenuButton, MenuItem, MenuList, Select, Spacer } from "@chakra-ui/react";
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Language = {
    name: string;
    countryCode: string;
    languageCode: string;
}

interface LanguageProps {
    languages: Language[]
}

const LanguageSelector = (props: LanguageProps) => {
    const { i18n } = useTranslation();
    const { languages } = props;

    const [language, setLanguage] = useState<Language>(languages.find(lang => lang.languageCode === i18n.language) ||
        { name: "English", countryCode: "us", languageCode: "en" });

    const handleLanguageChange = useCallback((lang: Language) => {
        setLanguage(lang);
        console.log(`Language changed to ${lang.name}`);
        i18n.changeLanguage(lang.languageCode);
    }, [setLanguage, i18n]);

    return (
        <Menu>
            <MenuButton as={Button}>
                {<>{getUnicodeFlagIcon(language.countryCode)}</>}
            </MenuButton>
            <MenuList>
                {languages.map((lang) => (
                    <MenuItem icon={<>{getUnicodeFlagIcon(lang.countryCode)}</>} onClick={() => handleLanguageChange(lang)}>
                        {lang.name}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default LanguageSelector;