import { Box, Text, Button, Center, Link, Alert, AlertIcon, SimpleGrid } from "@chakra-ui/react";
import { useNavigate } from "react-router";
import { toEvent } from "../../routes";
import { Trans, useTranslation } from "react-i18next";

export interface CreatedEventProps {
  eventName: string;
  eventId: string;
}

export const CreatedEventComponent = (props: CreatedEventProps) => {
  const { eventName, eventId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const eventLink = window.location.href + "/" + eventId

  const onGoToEvent = () => {
    navigate(toEvent(eventId));
  }

  const onShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: t("created_event.share_popup.title", { eventName }),
          text: t("created_event.share_popup.text", { eventName }),
          url: eventLink,
        });
    }
  }

  return (
    <Box maxWidth="lg">
      <Center my="3">
        <Text fontSize="22">
          <Trans i18nKey="created_event.created" values={{ eventName }} components={{ strong: <strong /> }}/>
        </Text>
      </Center>
      <Center my="3">
        <Text>
          <Trans i18nKey="created_event.share"/>
        </Text>
      </Center>
      <Center my="6">
        <Link size="lg" color="teal.500" href={eventLink}>
          {eventLink}
        </Link>
      </Center>
      <SimpleGrid columns={[1, 2]}>
        <Button
          m="3"
          colorScheme="green"
          onClick={onGoToEvent}
        >
          <Trans i18nKey="created_event.goto"/>
        </Button>
        <Button m="3" onClick={onShare}>
          <Trans i18nKey="created_event.share_button"/>
        </Button>
      </SimpleGrid>
      <Center my="3">
        <Alert status="info">
          <AlertIcon />
          <Box>
            <Text fontWeight="bold">
              <Trans i18nKey="created_event.remember"/>
            </Text>
            <Text>
              <Trans i18nKey="created_event.remember_text"/>
            </Text>
          </Box>
        </Alert>
      </Center>
    </Box>
  );
}
