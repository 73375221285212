import { Center, Heading } from "@chakra-ui/layout";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { toMainPage } from "../../routes";
import { Image } from '@chakra-ui/react'
import { useTranslation } from "react-i18next";

export const LogoHeader = () => {
  const { t } = useTranslation();
  const navigator = useNavigate();
  const returnToMainPage = useCallback(() => {
    navigator(toMainPage());
  }, [navigator])
  return (
    <Center
      flexDirection={"row"}
      onClick={returnToMainPage}
      gap={3}
      >
      <Image
        height={14}
        src={'/apple-touch-icon.png'}
        alt="Secret Santa" />
      <Heading
        textAlign="center"
        maxWidth="60%" // Ograniczenie szerokości tekstu
      >
        {t('title')}
      </Heading>
    </Center>
  );
}