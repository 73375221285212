import { Route, Routes, Navigate } from "react-router-dom";
import { ChooseParticipantPage } from "./pages/event/ChooseParticipantPage";
import { CreateEventPage } from "./pages/create/CreateEventPage";
import { EventPage } from "./pages/event/EventPage";
import { WelcomePage } from "./pages/WelcomePage";
import { Box, Center, Flex } from "@chakra-ui/layout";
import { LogoHeader } from "./pages/components/LogoHeader";
import { CheckSecretSantaPage } from "./pages/check/CheckSecretSantaPage";
import { ROUTES } from "./routes";
import { useColorMode } from "@chakra-ui/color-mode";
import { IconButton } from "@chakra-ui/button";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import Footer from "./pages/components/Footer";
import { Spacer } from "@chakra-ui/react";
import LanguageSelector from "./pages/components/LanguageSelector";

export const App = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <Flex w="100vw" h="100vh" direction="column" maxWidth="100vw">
      <Flex w="100vw" direction="row-reverse" py="3" px="6" gap={2}>
        <IconButton
          aria-label="Change color mode"
          onClick={toggleColorMode}
          icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
        />
        <LanguageSelector languages={[
          { name: "English", countryCode: "us", languageCode: "en" },
          // { name: "English", countryCode: "gb", languageCode: "en" },
          { name: "Polski", countryCode: "pl", languageCode: "pl" },
          { name: "Italiano (auto)", countryCode: "it", languageCode: "it" },
          { name: "Français (auto)", countryCode: "fr", languageCode: "fr" },
          { name: "हिन्दी (auto)", countryCode: "in", languageCode: "hi" }
        ]} />
      </Flex>
      <Center>
        <Box maxWidth="100vw">
          <LogoHeader />
          <Box m="6">
            <Routes>
              <Route path={ROUTES.CREATE_EVENT} element={<CreateEventPage />} />
              <Route path={ROUTES.EVENT_DETAILS} element={<EventPage />} />
              <Route path={ROUTES.EVENT_CHECK} element={<ChooseParticipantPage />} />
              <Route path={ROUTES.EVENT_CHECK_USER} element={<CheckSecretSantaPage />} />
              <Route path={ROUTES.ROOT} element={<WelcomePage />} />
              <Route path="*" element={<Navigate to={ROUTES.ROOT} />} />
            </Routes>
          </Box>
        </Box>
      </Center>
      <Spacer />
      <Footer />
    </Flex>
  );
};
