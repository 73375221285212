import { MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { CreatedEventProps } from "./CreatedEventComponent";
import * as Yup from "yup";
import { SubmitButton } from "../components/SubmitButton";
import { useCallback, useState } from "react";
import { number } from "yup";
import { EventInput, registerEvent } from "../../api";
import { useTranslation } from "react-i18next";

interface ComponentProps {
  setEvent: (event: CreatedEventProps) => void;
}

export const CreateEventComponent = (props: ComponentProps) => {
  const { setEvent } = props;
  const { t } = useTranslation();

  const CreateEventSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, ({ value, min }) => t('create_event.requirements.too_short', { value, min }))
      .max(50, ({ value, max }) => t('create_event.requirements.too_long', { value, max }))
      .required(t('create_event.requirements.required')),
    date: Yup.date()  
      .min(
        new Date(new Date().setDate(new Date().getDate()-1)),
        t('create_event.requirements.date_from_past')
      )
      .required(t('create_event.requirements.required'))    ,
    limit: Yup.number()
      .integer(t('create_event.requirements.integer_limit'))
      .positive(t('create_event.requirements.number_limit'))
      .moreThan(0, t('create_event.requirements.positive_limit'))
      .required(t('create_event.requirements.required')),
    participants: Yup.array()
      .of(Yup.string()
        .min(3, ({ value, min }) => t('create_event.requirements.participant_too_short', { value, min }))
        .max(50, ({ value, max }) => t('create_event.requirements.participant_too_long', { value, max }))
        .required(t('create_event.requirements.required'))
      )
      .min(3, ({ value, min }) => t('create_event.requirements.min_participants', { value, min }))
      .required(t('create_event.requirements.required')),
  });

  const onSubmit = useCallback(
    (values: EventInput) => {
      registerEvent(values)
        .then(response => {
          setEvent({
            eventId: response?.id!,
            eventName: response?.name!
          })
        })
    },
    [setEvent]
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      date: new Date().toISOString().split('T')[0],
      limit: 10,
      participants: ["", "", ""],
    },
    validationSchema: CreateEventSchema,
    validateOnChange: true,
    onSubmit: onSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Flex maxW={"lg"} flexDirection={"column"}>
            <FormControl
              my="3"
              isRequired
              isInvalid={(formik.errors.name && formik.touched.name) || false}
            >
              <FormLabel>{t('create_event.form.event_name')}</FormLabel>
              <Input
                type="text"
                id="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
              <FormHelperText>
                {t('create_event.form.event_name_description')}
              </FormHelperText>
            </FormControl>
            <FormControl
              my="3"
              isRequired
              isInvalid={Boolean(formik.errors.date && formik.touched.date)}
            >
              <FormLabel>{t('create_event.form.event_date')}</FormLabel>
              <Input
                type="date"
                id="date"
                onChange={formik.handleChange}
                value={formik.values.date}
              />
              <FormErrorMessage>{formik.errors.date}</FormErrorMessage>
              <FormHelperText>
                {t('create_event.form.event_date_description')}
              </FormHelperText>
            </FormControl>
            <FormControl
              my="3"
              isRequired
              isInvalid={Boolean(formik.errors.limit && formik.touched.limit)}
            >
              <FormLabel>{t('create_event.form.event_limit')}</FormLabel>
              <Input
                type="number"
                id="limit"
                onChange={formik.handleChange}
                value={formik.values.limit}
              />
              <FormErrorMessage>{formik.errors.limit}</FormErrorMessage>
              <FormHelperText>
                {t('create_event.form.event_limit_description')}
              </FormHelperText>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={
                (formik.errors.participants && formik.touched.participants) ||
                false
              }
            >
              <FieldArray
                name="participants"
                render={(arrayHelpers) => (
                  <Box>
                    <FormLabel>{t('create_event.form.participants')}</FormLabel>
                    {Boolean(formik.values?.participants.length) && (
                      formik.values.participants.map((participant, index) => (
                        <Box my="3">
                          <Stack direction="row" key={index}>
                            <Input
                              name={`participants.${index}`}
                              type="text"
                              value={participant}
                              onChange={formik.handleChange}
                            />
                            <IconButton
                              colorScheme="red"
                              aria-label="Remove participant"
                              icon={<MinusIcon />}
                              onClick={() => arrayHelpers.remove(index)}
                            />
                          </Stack>
                          <FormErrorMessage>{ Array.isArray(formik.errors.participants) ? formik.errors.participants?.at(index) : null }</FormErrorMessage>
                        </Box>
                      ))
                    )}
                    <Button
                      my="3"
                      // align="end"
                      size="md"
                      variant="link"
                      onClick={() => arrayHelpers.push("")}
                    >
                      + {t('create_event.form.add_participants')}
                    </Button>
                  </Box>
                )}
              />
              <FormErrorMessage>{ typeof formik.errors.participants === 'string' ? formik.errors.participants : null }</FormErrorMessage>
            </FormControl>
            <SubmitButton name={t('create_event.form.submit')} />
        </Flex>
      </form>
    </FormikProvider>
  );
};
