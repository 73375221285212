import React, { useEffect, useRef, useState } from "react";
import { Box, Text, Icon, Flex, Slider, Button, Heading, Link, VStack } from "@chakra-ui/react";
import { Comment } from "../../comments";
import { Image } from '@chakra-ui/react'
import { useTranslation } from "react-i18next";

interface ComponentProps {
    comments: Comment[]
}

const sourceImages: { [key: string]: string } = {
    reddit: 'reddit.png',
    buymeacoffee: 'buymeacoffee.png',
};

const source: { [key: string]: string } = {
    reddit: 'https://www.reddit.com/r/SideProject/comments/17ueg9i/lets_be_santa_secret_santa_generator_without/',
    buymeacoffee: 'https://buymeacoffee.com/emlagowski',
};

const CommentSlider = (props: ComponentProps) => {
    const { t } = useTranslation();
    const { comments } = props;
    return (
        <>
            <Heading as='h2' size='lg'>{t('feedback')}</Heading>
            <Flex
                overflow={"auto"}
                maxW={'6xl'}
                gap="4"
                py="6"
                px="2"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none',  // IE and Edge
                    'scrollbar-width': 'none',  // Firefox
                }}>
                {comments.map((comment: Comment, index: number) => (
                    <Box
                        key={index}
                        borderWidth="thin"
                        borderRadius="lg"
                        p={4}
                        maxW={{ base: "80%", sm: "45%", md: "400px" }}
                        flex="0 0 auto"
                        boxShadow="lg"
                    >
                        <Link href={source[comment.source]} isExternal _hover={{ textDecoration: 'none' }}>
                            <Flex mb="2" gap="3" alignItems="center">
                                <Image
                                    src={sourceImages[comment.source]}
                                    alt={comment.source}
                                    boxSize="36px"
                                    rounded={"full"}
                                />
                                <Box>
                                    <Text fontWeight="bold">{comment.username}</Text>
                                    <Text fontWeight="hairline" fontSize={"xs"}>{comment.date.toLocaleDateString()}</Text>
                                </Box>
                            </Flex>
                            <Text fontStyle="italic">{comment.text}</Text>
                        </Link>
                    </Box>
                ))}
            </Flex>
        </>
    );
};

export default CommentSlider;