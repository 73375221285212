import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Flex, Heading, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, useDisclosure } from "@chakra-ui/react";
import React, { useRef } from "react";
import { BuyMeCoffee } from "./BuyMeCoffee";
import { useTranslation } from "react-i18next";

interface StatProps {
    title: String
    value: String
    percentage: string
    dialogTitle: string
    dialogContent: string
    type?: 'increase' | 'decrease' | undefined
    supportButton?: boolean
}

const SingleStat = (props: StatProps) => {
    const { title, value, percentage, type, supportButton, dialogTitle, dialogContent } = props;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef(null)
    return (
        <Stat
            onClick={onOpen}
            mx={3}
            textAlign="center"
        >
            <AlertDialog
                motionPreset='slideInBottom'
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>{dialogTitle}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {dialogContent}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} size={supportButton != null  && supportButton ? "lg" : "md"}>
                            OK
                        </Button>
                        { supportButton != null  && supportButton ? <Box ml={3}><BuyMeCoffee/></Box> : <></> }
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <StatLabel whiteSpace="nowrap">{title}</StatLabel>
            <StatNumber whiteSpace="nowrap">{value}</StatNumber>
            <StatHelpText whiteSpace="nowrap">
                { (type == "increase" || type == "decrease") ? <StatArrow type={type} /> : <></>}
                {percentage}
            </StatHelpText>
        </Stat>
    )
}
interface StatsProps {

}

const Stats = (props: StatsProps) => {
    const { t } = useTranslation();
    const { } = props;
    return (
        <>
            <Heading as='h2' size='lg'>{t("statistics.title")}</Heading>
            <Flex
                overflow={"auto"}
                maxW={'6xl'}
                gap="4"
                py="6"
                px="2"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none',  // IE and Edge
                    'scrollbar-width': 'none',  // Firefox
                }}>
                <Stat>
                    <StatLabel>{t("statistics.year")}</StatLabel>
                    <StatNumber>2024</StatNumber>
                </Stat>

                <SingleStat 
                    title={t('statistics.events.title')}
                    value="8k" 
                    percentage="2119%" 
                    type="increase" 
                    dialogTitle={t("statistics.events.dialog")}
                    dialogContent={t("statistics.events.content")}
                />
                <SingleStat 
                    title={t('statistics.participants.title')}
                    value="60k" 
                    percentage="2463%" 
                    type="increase" 
                    dialogTitle={t("statistics.participants.dialog")}
                    dialogContent={t("statistics.participants.content")}
                />
                <SingleStat 
                    title={t('statistics.countries.title')}
                    value="&gt; 100" 
                    percentage="454%" 
                    type="increase" 
                    dialogTitle={t("statistics.countries.dialog")}
                    dialogContent={t("statistics.countries.content")}
                />
                <SingleStat 
                    title={t('statistics.supporters.title')}
                    value="25" 
                    percentage="🔥🔥🔥%" 
                    dialogTitle={t("statistics.supporters.dialog")}
                    dialogContent={t("statistics.supporters.content")}
                    supportButton={true}
                />
                <SingleStat 
                    title={t('statistics.biggest.title')}
                    value="84 🎅" 
                    percentage="120%" 
                    type="increase" 
                    dialogTitle={t("statistics.biggest.dialog")}
                    dialogContent={t("statistics.biggest.content")}
                />
                <SingleStat 
                    title={t('statistics.average.title')}
                    value="7.6 🎅" 
                    percentage="12%" 
                    type="increase" 
                    dialogTitle={t("statistics.average.dialog")}
                    dialogContent={t("statistics.average.content")}
                />

            </Flex>
        </>
    )
}

export default Stats;