import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../routes";
import { SubmitButton } from "./components/SubmitButton";
import { Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber } from "@chakra-ui/react";
import CommentSlider from "./components/CommentSlider";
import { comments } from "../comments";
import Stats from "./components/Stats";
import { useTranslation } from "react-i18next";

// Funkcja do przetasowania tablicy
const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const WelcomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onOrganizeHandle = () => {
    navigate(ROUTES.CREATE_EVENT);
  }

    // Przetasowanie tablicy comments
    const shuffledComments = shuffleArray([...comments]);

  return (
    <Flex flexFlow={"column"}>
      <Center flexDirection={"column"} mb="8">
        <Text align={"center"}>{t('welcome.first_line')}</Text>
        <Text align={"center"}>{t('welcome.second_line')}</Text>
        <form onSubmit={onOrganizeHandle}>
          <SubmitButton name={t('organize_event_button')} />
        </form>
      </Center>
      <CommentSlider comments={shuffledComments} />
      <Stats/>
    </Flex>
  );
}
