import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from "i18next-http-backend";

// Import translations
import translationEN from './locales/en/translations.json';
import translationPL from './locales/pl/translations.json';
import translationIT from './locales/it/translations.json';
import translationFR from './locales/fr/translations.json';
import translationIN from './locales/hi/translations.json';

const resources = {
    en: {
        translation: translationEN,
    },
    pl: {
        translation: translationPL,
    },
    it: {
        translation: translationIT,
    },
    fr: {
        translation: translationFR,
    },
    hi: {
        translation: translationIN,
    },
};

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        supportedLngs: Object.keys(resources),
        // lng: 'en', // Set the default language
        fallbackLng: 'en', // Fallback to English if a translation is missing
        keySeparator: '.', // Allow for nested translations without using dots
        interpolation: {
            escapeValue: false,
        },
        detection: {
            // Options for language detection
            order: ['header', 'querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'], // Where to store the detected language
        },
        debug: true,
    });

export default i18n;